@use './config/' as *;
@forward '../scss/main.scss';
.waf-document.widget-layout-01 {
  .waf-head {
    height: 35rem;
    @extend %flex-c-c;
  }
  @extend %page-head-blue-bg;
  @extend %py-0;
  .head-tab {
    @extend %d-none;
  }
  .head-wrap {
    margin-block: 11.12rem;
  }
  .waf-head {
    &::before {
      top: 12rem;
    }
  }
  .waf-body {
    max-width: unset;
    margin-top: var(--space-20-neg);
    @extend %px-4;
    @extend %py-10;
  }
  .search {
    &-title {
      @extend %py-4;
      @extend %title-40;
    }
    &-input {
      @extend %body-l;
    }
    &-results-modal {
      top: unset;
      max-height: unset;
      @extend %d-block;
      @extend %relative;
      @extend %p-0;
      @extend %w-100;
    }
    &-results-wrapper {
      left: 0;
      box-shadow: 0rem 0.2rem 0.8rem var(--neutral-10);
      border-radius: 0 0 1.6rem 1.6rem;
      @extend %w-100;
      @extend %absolute;
      @extend %bg-neutral-0;
      @extend %p-4;
    }
    &-results-item {
      @extend %py-2;
      @extend %pl-12;
      @extend %pr-0;
      @extend %gap-2;
      &::after {
        top: 0.95rem;
        left: 1.2rem;
        font-weight: 700;
        @include icon(document, 20);
        @extend %absolute;
      }
      .text {
        @extend %body-l;
      }
      .date,
      .btn-link {
        @extend %neutral-70;
        @extend %body-m;
      }
      .btn-link {
        &:not(:last-child)::after {
          content: '|';
          @extend %mx-1;
          // @extend %pl-1;
        }
      }
    }
  }
  .card {
    &-title {
      letter-spacing: -2%;
      @extend %py-4;
      @extend %mb-4;
      @extend %title-40;
      @extend %capitalize;
    }
    &-list-wrap {
      @extend %mb-6;
    }
    &-list {
      list-style-type: disc;
      @extend %pl-6;
    }
    &-list-title {
      @extend %body-lb;
      @extend %primary-50;
      @extend %mb-0;
      &.active {
        border-radius: 0.2rem;
        @extend %bg-primary-0;
      }
    }
    &-item {
      &::marker {
        @extend %primary-50;
      }
      &.active {
        border-radius: 0.2rem;
        @extend %bg-primary-0;
      }
    }
    &-link {
      @extend %body-l;
      @extend %primary-50;
    }
  }
  .table {
    @extend %x-auto;
    &-row {
      justify-content: flex-start;
      align-items: stretch;
      border-bottom: unset;
    }
    &-head {
      @extend %mb-4;
      .text {
        @extend %body-lb;
      }
      .name,
      .date {
        @extend %flex-sb-c;
        &::after {
          @include icon(arrows-up-down, 16);
          @extend %neutral-40;
        }
      }
    }
    &-data {
      height: auto;
      flex-grow: 1;
      @extend %neutral-70;
      @extend %py-3;
      @extend %px-2;
    }
    &-body {
      .table-data {
        border-bottom: 0.1rem solid var(--neutral-20);
      }
      .name {
        @extend %text-left;
        justify-content: flex-start;
      }
      .date {
        justify-content: flex-end;
      }
    }
    .meta {
      &-link {
        @extend %body-mb;
        @extend %primary-50;
      }
      &-categories {
        @extend %text-s;
        .btn-link {
          &:not(:last-child)::after {
            content: '|';
            margin-inline: 0.3rem;
          }
        }
      }
    }
    .name {
      width: 60%;
      @extend %px-0;
    }
    .date {
      width: 30%;
    }
    .size,
    .type {
      width: 20%;
    }
    .type {
      .text {
        word-break: break-all;
      }
    }
  }
  .loadmore-wrap {
    @extend %mt-4;
    @extend %flex-fe-c;
  }
  .btn-loadmore {
    @extend %btn-outline;
    @extend %capitalize;
  }
  .item-info {
    @extend %flex-column;
    @extend %gap-2;
    &-link {
      line-height: 1;
    }
  }
}
@include mq(col-tablet) {
  .waf-document.widget-layout-01 {
    // .head-wrap {
    //   margin-block: 23.3rem 15rem;
    // }
    .waf-head {
      height: 37rem;
      &::before {
        top: 50%;
      }
    }
    .waf-body {
      max-width: var(--container-max-width);
    }
    .card {
      &-section {
        display: flex;
        gap: var(--space-6);
        margin-top: var(--space-6);
      }
    }
    .search-results-item {
      &::after {
        top: 2rem;
      }
    }
    .item-info {
      flex-direction: row;
      gap: var(--space-4);
    }
  }
}
@include mq(col-lg) {
  .waf-document.widget-layout-01 {
    .waf-body {
      padding: var(--space-20) var(--space-10);
    }
    // .head-wrap {
    //   margin-block: 33.4rem 0rem;
    // }
    .card {
      &-categories {
        flex-basis: 25%;
      }
      &-content {
        flex-basis: calc(75% - var(--space-6));
      }
    }
    .table {
      .name {
        width: 65%;
      }
      .size,
      .type,
      .date {
        width: 10%;
      }
    }
  }
}