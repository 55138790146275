@use "../config/" as *;
.waf-template {
    .waf-head {
        height: 35rem;
        @extend %flex-c-c;
    }
    @extend %border-radius-m;
    @extend %bg-neutral-0;
    @extend %page-head-blue-bg;
    &.waf-component {
        @extend %p-0;
    }
    .waf-body {
        max-width: 100%;
        margin-top: var(--space-12-neg);
        @extend %p-4;
    }
    .head-title {
        @extend %title-40;
        @extend %neutral-100;
    }
    .title {
        @extend %title-50;
        @extend %my-8;
    }
    p,
    li,
    .text {
        @extend %body-l;
        @extend %mb-6;
        > a {
            display: inline-block;
        }
        &:empty {
            @extend %d-none;
        }
    }
    li {
        margin-bottom: var(--space-3);
    }
    .action-link {
        @extend %body-mb;
        @extend %flex-n-c;
        @extend %gap-2;
        a {
            @extend %btn-outline;
        }
    }
    a {
        // text-decoration: underline;
        @extend %d-block;
        // @extend %primary-50;
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: auto;
    }
    ul,
    ol {
        @extend %pl-6;
        @extend %mb-6;
    }
    img,
    iframe {
        @extend %w-100;
        @extend %h-100;
    }
    iframe {
        height: 64rem;
    }
    .table-responsive {
        @extend %x-auto;
    }
    table {
        border: none;
        @extend %mb-6;
    }
    thead {
        @extend %bg-neutral-10;
    }
    tr {
        height: 7.2rem;
    }
    th,
    td {
        border-inline: none;
        @extend %text-left;
    }
    .action-tiles {
        flex-wrap: wrap;
        @extend %flex;
        @extend %gap-4;
        a {
            min-height: 9.6rem;
            max-width: 24rem;
            border: .1rem solid var(--neutral-20);
            background: var(--neutral-0) linear-gradient(223deg, var(--informative-10) 0%, hsl(var(--hsl-neutral-5) / 0) 75.04%);
            isolation: isolate;
            overflow: hidden;
            text-decoration: none;
            @extend %p-5-4;
            @extend %border-radius-s;
            @extend %flex-sb-fe;
            @extend %relative;
            @extend %primary-50;
            @extend %body-mb;
            @extend %flex-sb-fe;
            @extend %w-100;
            &:hover {
                border-color: var(--dark-focus-0);
            }
            &::before,
            &::after {
                content: "";
                pointer-events: none;
                @extend %zindex-pattern;
            }
            &::before {
                content: "";
                width: 23rem;
                aspect-ratio: 1;
                filter: invert(70%) sepia(96%) saturate(700%) hue-rotate(200deg) brightness(80%) contrast(250%);
                opacity: 1;
                @include position(null, -3.2rem, -1.5rem);
                @include bgImg('svg/vector.svg', center, contain, no-repeat);
            }
            &::after {
                line-height: 1;
                @include icon(arrow-long-right, 24);
                @extend %primary-50;
            }
            &[download] {
                &::after {
                    @include icon(arrow-down-tray, 24);
                }
            }
        }
    }
    .template-component {
        @extend %mb-6;
    }
    @extend %accordion-common;
}
.accordion-one-column {
    margin-bottom: var(--space-6);
}
.wtc-page .waf-template .waf-head {
    margin-top: var(--secondary-header-height);
}
@each $federation, $federation-img in $federation-bg {
    .#{$federation} {
        .waf-template {
            .waf-head {
                background: url("/static-assets/images/cssimages/world-ranking/#{$federation-img}.png?v=#{$image-version}") bottom center/cover no-repeat;
                &::before {
                    content: unset;
                }
            }
        }
    }
}
@include mq(col-tablet) {
    .waf-template {
        .head-title {
            font-size: 4rem;
        }
        .waf-head {
            height: 37rem;
            margin-top: 0;
        }
        .waf-body {
            padding: var(--space-14) var(--space-16);
            max-width: var(--container-max-width);
        }
        .title {
            font-size: 2.4rem;
            margin-top: 0;
        }
        .text-two-column,
        .text-image-two-column,
        .table-two-column,
        .accordion-two-column,
        .tiles-two-column {
            .content-body,
            .template-body {
                display: grid;
                gap: var(--space-4);
                grid-template-columns: 40% calc(60% - var(--space-4));
            }
        }
    }
    .wtc-page .waf-template .waf-head {
        margin-top: 0;
    }
}