@use "../config/" as *;

.snackbar {
	&-wrap {
		width: fit-content;
		min-height: 4rem;
		z-index: 4;
		visibility: hidden;
		@include position-combo("x-center", null, 0, fixed);
		@extend %p-2-4;
		@extend %border-radius-xs;
		@extend %bg-primary-50;
		&.show {
			animation: fadeIn .5s forwards, fadeOut .5s 2.5s forwards;
			visibility: visible;
		}
	}
	&-text {
		@extend %neutral-0;
		@extend %body-l;
	}
}