@use "variables" as *;
@use "mixins" as *;
@use "map" as *;
@use "animation" as *;
@use "shades" as *;
@use "buttons" as *;
// font sizes start
@for $size from 8 through 60 {
  $font-size : #{calc($size * .1rem)};
  @each $font-name,
  $font-data in $font-detail {
    @each $font-alias,
    $font-details in $font-data {
      @each $font-family,
      $font-weight in $font-details {
        %font-#{$size}-#{$font-alias} {
          font-size: $font-size;
          font-weight: $font-weight;
        }
      }
    }
  }
  %font-#{$size} {
    font-size: $font-size;
  }
}
%font-0 {
  font-size: 0;
}
// title font starts
@each $number,
$title in $titles {
  $font-size: nth($title, 1);
  $line-height: nth($title, 2);
  %title-#{$number} {
    // font: 700 #{$font-size}/#{$line-height};
    font-size: calc($font-size * 0.1rem);
    line-height: #{$line-height};
    font-weight: 700;
  }
}
// how to use
// %title-10 : here 10 means the biggest size and it goes till 60 which is the smallest
// values changes by 10,20,30,40,50,60
// title font ends
// font sizes end
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
%relative {
  position: relative;
}
%absolute {
  position: absolute;
}
// absolute start
%position-y-center {
  @include position-combo(y-center);
}
%position-x-center {
  @include position-combo(x-center);
}
%position-center {
  @include position-combo(center);
}
%position-t-l {
  @include position-combo(tl);
}
%position-t-r {
  @include position-combo(tr);
}
%position-b-l {
  @include position-combo(bl);
}
%position-b-r {
  @include position-combo(br);
}
// absolute end
// border-radius starts
%full-radius {
  border-radius: var(--full-radius)
}
%half-radius {
  border-radius: var(--half-radius);
}
%circle-radius {
  border-radius: 50%;
}
%rounded-radius {
  border-radius: 50vmax;
}
@each $radius,
$value in $border-radius {
  %border-radius-#{$radius} {
    border-radius:#{($value)*0.1rem};
  }
}
// how to use
// %border-radius-xs; ==> 4
// %border-radius-s; ==> 8
// %border-radius-m; ==> 16
// %border-radius-l; ==> 24
// %border-radius-l; ==> 32
// border-radius ends
// convention used t=top,l=left,b=bottom,r=right,y=block[top-bottom],x=inline[left-right],m=margin,p=padding
// spacing starts
@each $direction,
$direction-value in $directions {
  @for $space from 0 through $max-space-count {
    %m#{$direction}-#{$space} {
      margin#{$direction-value}: var(--space-#{$space});
    }
    %p#{$direction}-#{$space} {
      padding#{$direction-value}: var(--space-#{$space});
    }
    %m#{$direction}-#{$space}-neg {
      margin#{$direction-value}: var(--space-#{$space}-neg);
    }
    %p#{$direction}-#{$space}-neg {
      padding#{$direction-value}: var(--space-#{$space}-neg);
    }
  }
  %m#{$direction}-auto {
    margin#{$direction-value}: auto;
  }
  %p#{$direction}-auto {
    padding#{$direction-value}: auto;
  }
}
@for $i from 0 through $max-space-count {
  @for $j from 0 through $max-space-count {
    %m-#{$i}-#{$j} {
      margin: var(--space-#{$i}) var(--space-#{$j});
    }
    %p-#{$i}-#{$j} {
      padding: var(--space-#{$i}) var(--space-#{$j});
    }
  }
}
@each $direction,
$direction-value in $directions {
  @for $i from 0 through $max-space-count {
    @for $j from 0 through $max-space-count {
      %m-#{$direction}-#{$i}-#{$j} {
        margin#{$direction-value}: var(--space-#{$i}) var(--space-#{$j});
      }
      %p-#{$direction}-#{$i}-#{$j} {
        padding#{$direction-value}: var(--space-#{$i}) var(--space-#{$j});
      }
    }
  }
}
//how to use
// @extend %p-y-12-20;
// @extend %m-y-12-20;
// @extend %p-x-12-20;
// @extend %m-x-12-20;
//how to use
// @extend %m-t-2; margin-top
// @extend %m-x-2; margin-inline
// @extend %m-y-2; margin-block
// @extend %m-2-4; margin-block-inline
@for $space from 0 through $max-space-count {
  %m-#{$space} {
    margin: var(--space-#{$space});
  }
  %p-#{$space} {
    padding: var(--space-#{$space});
  }
  %gap-#{$space} {
    gap: var(--space-#{$space});
  }
  %m-#{$space}-neg {
    margin: var(--space-#{$space}-neg);
  }
  %p-#{$space}-neg {
    padding: var(--space-#{$space}-neg);
  }
  %gap-#{$space}-neg {
    gap: var(--space-#{$space}-neg);
  }
}
%m-auto {
  margin: auto;
}
%p-auto {
  padding: auto;
}
//how to use
// @extend %m-1
// @extend %p2
// @extend %m-1-neg
// @extend %p2-neg
// spacing ends
// For width & height
@for $i from 1 through 10 {
  %h-#{calc($i * 10)} {
    height: calc($i * 10%);
  }
  %w-#{calc($i * 10)} {
    width: calc($i * 10%);
  }
}
//
@each $direction,
$direction-value in $directions {
  @for $i from 0 through $max-space-count {
    @for $j from 0 through $max-space-count {
      %m-#{$direction}-#{$i}-#{$j} {
        margin#{$direction-value}: var(--space-#{$i}) var(--space-#{$j});
      }
      %p-#{$direction}-#{$i}-#{$j} {
        padding#{$direction-value}: var(--space-#{$i}) var(--space-#{$j});
      }
    }
  }
}
//how to use
// @extend %p-y-12-20;
// @extend %m-y-12-20;
// @extend %p-x-12-20;
// @extend %m-x-12-20;
// text transform start
%uppercase {
  text-transform: uppercase;
}
%lowercase {
  text-transform: lowercase;
}
%capitalize {
  text-transform: capitalize;
}
// text transform end
// text alignment start
%text-center {
  text-align: center;
}
%text-left {
  text-align: left;
}
%text-right {
  text-align: right;
}
// text alignment end
// display default start
%d-none {
  display: none;
}
%d-block {
  display: block;
}
%d-inline-block {
  display: inline-block;
}
%d-inline {
  display: inline;
}
// display default end
// Flex classes start
%flex {
  @include flex-config(flex);
}
%flex-column {
  @include flex-config(flex, column);
}
@each $justify,
$justify-value in $flex-spacing {
  @each $align,
  $align-value in $flex-spacing {
    %flex#{$justify}#{$align} {
      @include flex-config(flex, null, #{$justify-value}, #{$align-value});
    }
    %flex-column#{$justify}#{$align} {
      @include flex-config(flex, column, #{$justify-value}, #{$align-value});
    }
  }
}
%flex-wrap {
  flex-wrap: wrap;
}
%flex-nowrap {
  flex-wrap: nowrap;
}
// Flex classes end
// shortform
// 	sa: space-around,
// 	sb: space-between,
// 	se: space-evenly,
// 	fe: flex-end,
// 	fs: flex-start,
// 	c:center,
// 	s:stretch,
// 	u:unset,
// 	n:null
//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-null-c;
// @extend %flex-sb-c;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
// @extend %flex-column-c-sb;
%transition {
  transition: 0.3s;
}
// Overflow start
%hidden {
  overflow: hidden;
}
%x-auto {
  overflow-x: auto;
}
%x-scroll {
  overflow-x: scroll;
}
%x-hidden {
  overflow-x: hidden;
}
%y-auto {
  overflow-y: auto;
}
%y-scroll {
  overflow-y: scroll;
}
%y-hidden {
  overflow-y: hidden;
}
// Overflow end
// shades starts
@each $shade,
$numbers in $hex-shades {
  @each $number,
  $value in $numbers {
    %#{$shade}-#{$number} {
      color: var(--#{$shade}-#{$number});
    }
    %bg-#{$shade}-#{$number} {
      background-color: var(--#{$shade}-#{$number});
    }
  }
}
%bg-transparent {
  background-color: transparent;
}
// how to use
// with color : %color-10  (color are maped map.scss and the number is the shade which is in figma )
// @extend %neutral-10
// for bg
// @extend %bg-neutral-10
// color : var(--neutral-10);
// shades ends
// shadows starts
%shadow-high {
  box-shadow: 0 0.25rem 1.5rem hsl(0deg 0% 0% / 10%);
}
%shadow-medium {
  box-shadow: 0 0.125rem 1rem hsl(0deg 0% 0% / 10%);
}
%shadow-low {
  box-shadow: 0 0.125rem 0.5rem hsl(0deg 0% 0% / 10%);
}
// body text starts
@each $weight,
$size in $body-texts {
  $font-size: nth($size, 1);
  $font-weight: nth($size, 2);
  %body-#{$weight} {
    font: $font-weight calc($font-size * 0.1rem)/1.5 Manrope, serif;
  }
  %text-#{$weight} {
    font: $font-weight calc($font-size * 0.1rem)/1 Manrope, serif;
  }
}
// how to use
// for body text
// %body-s ==> for body regular (replace last 's' with your need)
// %body-sb ==> for body bold (replace last 's' with your need)
// for text
// %text-s ==> for text regular (replace last 's' with your need)
// %text-sb ==> for text bold (replace last 's' with your need)
// s,sb,m,mb,l,lb
// body text ends
// shadows ends
@each $zindex-name,
$zindex-value in $zindex {
  %zindex-#{$zindex-name} {
    z-index:var(--z-#{$zindex-name});
  }
}
.badge {
  width: max-content;
  height: 3.2rem;
  border-radius: 0.8rem;
  @extend %flex-c-c;
  @extend %bg-neutral-5;
  @extend %gap-2;
  @extend %text-l;
  @extend %primary-50;
  @extend %px-2;
}
// Page Head Blue bg extend
%page-head-blue-bg {
  margin-inline: var(--space-2-neg);
  @extend %bg-neutral-5;
  .layout-wrapper {
    max-width: unset;
  }
  .waf-head {
    border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
    background: linear-gradient(341deg, var(--primary-90) -24.25%, var(--primary-50) 73.07%);
    overflow: hidden;
    height: 55rem;
    @include flex-config(flex, null, null, center);
    @extend %relative;
    &::before {
      content: "";
      width: calc(var(--window-inner-width) * 1.07);
      aspect-ratio: 1;
      background: url(/static-assets/images/svg/vector-gradient.svg) center / contain no-repeat;
      pointer-events: none;
      @include position(7rem, null, null, 32.5%);
    }
  }
  .waf-body {
    margin-top: -15rem;
    @include large-wrapper();
    @extend %border-radius-m;
    @extend %relative;
    @extend %bg-neutral-0;
  }
  .head-wrap {
    margin-block: 18.1rem;
    @include default-wrapper();
    .title {
      letter-spacing: -.064rem;
      @extend %neutral-0;
      @extend %title-30;
      @extend %capitalize;
    }
  }
  @include mq(col-tablet) {
    .waf-head {
      max-width: 100%;
      height: 45rem;
      border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
      &::before {
        width: calc(.59 * var(--window-inner-width));
        top: 50%;
        left: 45%;
        transform: translate(0, -46%);
      }
    }
    .waf-body {
      border-radius: var(--border-radius-l);
      margin-top: -8rem;
    }
    .head-wrap {
      margin-block: 23.3rem 15rem;
      .title {
        font-size: 5.6rem;
        letter-spacing: unset;
      }
    }
  }
}
// Common Eventstrip styles
%common-eventstrip {
  .card {
    &-item {
      border: 0.1rem solid var(--neutral-20);
      @extend %border-radius-m;
      @extend %bg-neutral-0;
      &:hover {
        @extend %bg-neutral-5;
        &:hover {
          .card-body,
          .card-action {
            @extend %bg-neutral-5;
          }
        }
      }
    }
    &-status {
      width: max-content;
      height: 3.6rem;
      border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
      @extend %relative;
      @extend %bg-neutral-0;
      @extend %flex-n-c;
      @extend %zindex-status;
      @extend %text-center;
      @extend %m-auto;
      @extend %p-2;
      @extend %gap-2;
      .text,
      .label {
        @extend %text-sb;
      }
      .date {
        @extend %text-lb;
      }
      em {
        @extend %text-s;
      }
    }
    &-body {
      height: auto;
      padding: var(--space-10) var(--space-6) var(--space-6) var(--space-6);
      @extend %border-radius-m;
      @extend %mt-8-neg;
      @extend %relative;
      @extend %flex-column;
      @extend %bg-neutral-0;
    }
    &-logo {
      width: 5.8rem;
      height: 5.8rem;
      padding: 0.5rem;
      box-shadow: 0 .2rem 1.5rem 0 hsl(var(--hsl-pure-black-0) / .15);
      @include position(var(--space-6-neg));
      @extend %bg-neutral-0;
      @extend %circle-radius;
      img {
        width: 4.8rem;
        height: 4.8rem;
        object-fit: cover;
        @extend %circle-radius;
      }
      .text {
        @extend %font-0;
      }
    }
    &-label {
      margin-top: 1.1rem;
      @extend %text-m;
      @extend %neutral-70;
    }
    &-title {
      @extend %neutral-100;
      @extend %title-40;
      @extend %mt-2;
    }
    &-tag {
      width: max-content;
      @extend %mt-auto;
      .text {
        @extend %tag-outline;
        @extend %text-mb;
      }
    }
    &-btn {
      width: max-content;
      @extend %mt-auto;
      .text {
        @extend %tag-outline;
        @extend %text-mb;
        @extend %capitalize;
      }
      &.card-register {
        z-index: 2;
        @extend %relative;
      }
      &.card-more {
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
        .btn-site {
          @extend %w-100;
          @extend %h-100;
          @extend %d-block;
          .text {
            @extend %d-none;
          }
        }
      }
    }
  }
}
// Common form input & Swiper previous & next styles
%common-input-swiper-btn {
  .btn-close,
  .item-close {
    @include position-combo("y-center", 0);
    .btn-text {
      @extend %font-0;
      &::before {
        @include icon(close, 16);
        @extend %neutral-100;
      }
    }
  }
  .swiper-button {
    &-prev,
    &-next {
      width: 6.4rem;
      height: 4.8rem;
      border: none;
      border-radius: unset;
      top: 50%;
      background: linear-gradient(270deg, var(--neutral-0) 63.04%, hsl(var(--hsl-neutral-0) / 0) 100%);
      align-items: center;
      justify-content: center;
      translate: 0 -50%;
      &::after {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 2.4rem;
        @extend %primary-50;
      }
    }
    &-prev {
      right: unset;
      left: var(--space-4-neg);
      background: linear-gradient(-270deg, var(--neutral-0) 63.04%, hsl(var(--hsl-neutral-0) / 0) 100%);
      &::after {
        content: map-get($map: $icons, $key: arrow-left);
      }
    }
    &-next {
      right: var(--space-4-neg);
      left: unset;
      &::after {
        content: map-get($map: $icons, $key: arrow-right);
      }
    }
    &-disabled {
      @extend %d-none;
    }
  }
  .site-search-form {
    @extend %relative;
    .search-input {
      border-bottom: .1rem solid var(--neutral-20);
      display: inline-block;
      @extend %w-100;
      @extend %p-4;
      @extend %neutral-100;
      @extend %font-16;
      &::placeholder {
        @extend %neutral-40;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        @extend %neutral-40;
      }
      &::-ms-input-placeholder {
        @extend %neutral-40;
      }
      &:focus {
        outline: none;
        border-bottom: .2rem solid var(--primary-50);
      }
      &::-webkit-search-cancel-button {
        @extend %d-none;
      }
    }
  }
}
// Tabs with Underline common styles
%tabs-underline {
  .tab {
    &s {
      height: auto;
      @extend %flex;
    }
    &-item {
      width: auto;
      height: auto;
      &.active {
        .tab-name {
          @extend %relative;
          &::before {
            content: "";
            height: .3rem;
            @include position-combo("bl");
            @extend %w-100;
            @extend %bg-primary-50;
          }
          .tab-text {
            @extend %neutral-100;
          }
        }
      }
    }
    &-name {
      height: 4.6rem;
      line-height: 1;
      @extend %px-4;
      @extend %flex-n-c;
      .text {
        @extend %neutral-70;
        @extend %text-m;
      }
    }
    &-text {
      color: var(--neutral-70);
      @extend %text-m;
    }
  }
  @include mq(col-tablet) {
    .tab {
      &s {
        overflow-x: auto;
      }
      &-name {
        height: 6.4rem;
        padding-inline: var(--space-6);
        .text {
          font-size: 1.6rem;
        }
      }
    }
  }
}
// Card label & content layout. Used in various Fourstar pages
%card-label-content {
  .card {
    &-section {
      padding: var(--space-10) var(--container-white-space);
      @extend %border-radius-m;
      @extend %bg-neutral-0;
      &:not(:first-child) {
        @extend %mt-20;
      }
    }
    &-head {
      @extend %mb-8;
    }
    &-label {
      letter-spacing: -.05rem;
      @extend %title-40;
    }
  }
  @include mq(col-tablet) {
    .card {
      &-section {
        padding-block: var(--space-20);
        border-radius: var(--border-radius-l);
        &:not(:first-child) {
          margin-top: var(--space-18);
        }
      }
      &-item {
        display: flex;
        gap: var(--space-6);
      }
      &-head {
        width: 31%;
        padding-block: var(--space-4);
        margin-bottom: 0;
      }
      &-body {
        width: calc(69% - var(--space-6));
        padding-block: var(--space-6);
        flex-grow: 1;
      }
    }
  }
}
// Event pages common button styles
.btn-icon-ar,
.btn-download {
  @extend %w-100;
  &.btn-outline {
    @extend %btn-outline-text-icon;
  }
  &:not(.btn-outline) {
    @extend %btn-fill-text-icon;
  }
  .text,
  .btn-text {
    @extend %flex-n-c;
    &::after {
      font-weight: 400;
    }
  }
  @include mq(col-tablet) {
    width: fit-content;
  }
}
.btn-icon-ar {
  .text::after,
  .btn-text::after {
    @include icon(arrow-right, 20);
  }
}
.btn-download {
  .text::after,
  .btn-text::after {
    @include icon(arrow-down-tray, 20);
  }
}
// Accordion common
%accordion-common {
  .accordion {
    &-item:not(:last-child) {
      .accordion-body {
        @extend %mb-4;
      }
      &:first-child {
        .accordion-header {
          @extend %pt-0;
        }
      }
    }
    &-header {
      border-bottom: .1rem solid var(--neutral-20);
      transition: margin-bottom .5s ease-in;
      @extend %py-4;
      @extend %mb-0;
      @extend %flex-sb-c;
      @extend %gap-4;
      &[aria-expanded='true'] {
        border-bottom: .1rem solid var(--focus-0);
        @extend %mb-4;
        ~ .accordion-body {
          padding-top: 0;
        }
      }
      &::after {
        font-size: 2.4rem;
      }
      .btn-text {
        @include truncate-text(2);
        @extend %title-50;
        @extend %text-left;
      }
    }
    &-body .text {
      @extend %body-l;
    }
  }
}
// Tab Section common
%tab-section-common {
  .tab-section {
    @extend %p-10-4;
    @extend %border-radius-m;
    @extend %bg-neutral-0;
    .tabs {
      border-bottom: .1rem solid var(--neutral-20);
      @extend %px-4;
      @extend %mx-4-neg;
      @extend %mb-2;
      @extend %flex;
    }
    .waf-select-box {
      @extend %mb-2;
    }
    .site-search-form {
      @extend %mb-14;
    }
    .table {
      overflow-x: auto;
      &-responsive {
        border: .1rem solid var(--neutral-20);
        @extend %border-radius-m;
      }
      &-header {
        @extend %py-4;
        @extend %bg-neutral-5;
        .title {
          line-height: 1.4;
          @include truncate-text(2);
          @extend %text-center;
        }
      }
      &-actions {
        @extend %w-100;
        @extend %flex-c-c;
        @extend %gap-2;
      }
      &-head {
        .text {
          font-weight: 700;
          @extend %neutral-70;
        }
        .table-data {
          @extend %bg-neutral-5;
        }
      }
      &-body {
        .text {
          font-weight: 400;
          line-height: 1.4;
          @extend %neutral-100;
          @extend %font-14;
          @extend %text-left;
        }
        .no-data-container {
          .text {
            font-weight: 700;
          }
        }
      }
      &-data {
        height: 7.2rem;
        border-bottom: .1rem solid var(--neutral-20);
        flex-basis: 8.5rem;
        justify-content: space-between;
        @extend %px-2;
        &-wrap {
          @extend %flex-n-c;
          @extend %gap-2;
          .image {
            width: 2.4rem;
            aspect-ratio: 1;
            @extend %circle-radius;
          }
        }
      }
      .text {
        text-transform: capitalize;
      }
    }
    .btn {
      min-height: unset;
      padding: 0;
      border-radius: unset;
      background-color: transparent;
      @extend %flex;
      &:hover {
        background-color: transparent;
      }
      &::before {
        font: 2rem/1 $font-icon;
        @extend %neutral-100;
      }
      &-pdf::before {
        @include icon(document, 20);
      }
      &-print::before {
        content: map-get($map: $icons, $key: printer);
      }
      &-excel::before {
        @include icon(document-chartbar, 20);
      }
      .text {
        @extend %font-0;
      }
      &-loadmore {
        @extend %btn-default;
        cursor: pointer;
      }
    }
    .loadmore-wrap {
      height: 8rem;
      @extend %flex-c-c;
    }
  }
  .waf-select-box {
    height: fit-content;
    border-bottom: .1rem solid var(--neutral-20);
    @extend %p-3-4;
    @extend %relative;
    &.active {
      .select-box-wrap {
        grid-template-rows: 1fr;
        .select-list {
          overflow-x: hidden;
          overflow-y: unset;
        }
      }
      .selected-label::after {
        transform: rotate(180deg);
      }
    }
    .sub-title {
      @extend %mb-1;
      @extend %neutral-70;
      @extend %text-s;
    }
    .select {
      &-box-wrap {
        max-height: 19.2rem;
        display: grid;
        box-shadow: 0 0.2rem 1.5rem 0 var(--neutral-20);
        grid-template-rows: 0fr;
        overflow-y: auto;
        transition: all .3s ease-in;
        @include position(100%, null, null, 0);
        @extend %w-100;
        @extend %zindex-waf-dropdown;
        @extend %bg-neutral-0;
      }
      &-list {
        overflow: hidden;
        @extend %d-block;
      }
    }
    .selected-label {
      height: 3.2rem;
      cursor: pointer;
      @extend %w-100;
      @extend %flex-sb-c;
      &::after {
        transition: transform .3s ease-in;
        @extend %text-l;
        @include icon(chevron-down, 24);
      }
    }
    .list-item {
      height: 4.8rem;
      border-bottom: .1rem solid var(--neutral-20);
      @extend %w-100;
      @extend %p-3-4;
      @extend %flex-sb-c;
      @extend %neutral-70;
      @extend %text-m;
      &:hover {
        @extend %bg-neutral-5;
      }
      &::after {
        @include icon(check, 20);
        @extend %d-none;
        @extend %positive-50;
      }
      &.active {
        @extend %neutral-100;
        &::after {
          display: inline-block;
        }
      }
    }
    .dropdown-close {
      @extend %d-none;
    }
  }
  @include mq(col-tablet) {
    .tab-section {
      padding-top: var(--space-6);
      padding-inline: calc(var(--container-white-space) / 2);
      border-radius: var(--border-radius-l);
      .tabs {
        padding-inline: calc(var(--container-white-space) / 2);
        margin-inline: calc(-1 * var(--container-white-space) / 2);
      }
      .tab-name .text {
        color: var(--neutral-100);
      }
      .form-wrapper {
        margin-bottom: var(--space-10);
        display: grid;
        align-items: flex-end;
        gap: var(--space-6);
        grid-auto-flow: column;
        grid-template-columns: 32% auto;
      }
      .waf-select-box,
      .site-search-form {
        margin-bottom: 0;
      }
      .table {
        @include custom-scroll();
        &-header {
          height: 7.6rem;
          padding-inline: var(--space-4);
          flex-grow: 1;
          gap: 1rem;
          @include flex-config(flex, null, space-between, center);
          .title {
            margin-bottom: 0;
            -webkit-line-clamp: 1;
            line-clamp: 1;
          }
        }
        &-actions {
          width: fit-content;
        }
        &-data {
          flex-basis: 7.66%;
        }
      }
    }
  }
}
// Event Card Small extend
%event-card-small {
  .card,
  .program {
    &-item {
      min-height: 9.6rem;
      border: .1rem solid var(--neutral-20);
      background: var(--neutral-0) linear-gradient(223deg, var(--informative-10) 0%, hsl(var(--hsl-neutral-5) / 0) 75.04%);
      isolation: isolate;
      overflow: hidden;
      @extend %p-5-4;
      @extend %border-radius-s;
      @extend %flex-sb-fe;
      @extend %relative;
      @extend %gap-4;
      &:not(:last-child) {
        @extend %mb-6;
      }
      &:hover {
        border-color: var(--dark-focus-0);
      }
      &::before,
      &::after {
        content: "";
        pointer-events: none;
        @extend %zindex-pattern;
      }
      &::before {
        content: "";
        width: 23rem;
        aspect-ratio: 1;
        background: url(/static-assets/images/svg/vector.svg?v=#{$image-version}) center / contain no-repeat;
        filter: invert(70%) sepia(96%) saturate(700%) hue-rotate(200deg) brightness(80%) contrast(250%);
        opacity: 1;
        @include position(null, -3.2rem, -1.5rem);
      }
      &::after {
        background: linear-gradient(180deg, hsl(var(--hsl-neutral-0) / 0) 0%, hsl(var(--hsl-neutral-5) / .9) 100%);
        @include position-combo(inset);
      }
    }
    &-content .text {
      @extend %d-none;
    }
  }
  .title {
    line-height: 1.4;
    @include truncate-text(2);
    @extend %mb-0;
    @extend %primary-50;
    @extend %title-50;
  }
  .btn-link {
    @extend %flex;
    &::before {
      line-height: 1;
      @include icon(arrow-right, 24);
      @extend %primary-50;
    }
    &::after {
      content: "";
      @include position-combo(inset);
    }
    .text,
    .btn-text {
      @extend %font-0;
    }
  }
  @include mq(col-tablet) {
    .card,
    .program {
      &-list {
        @include card-count(3, var(--space-4), wrap);
      }
      &-item:not(:last-child) {
        margin-bottom: 0
      }
    }
  }
  @include mq(col-desktop) {
    .card-list,
    .program-list {
      @include card-count(4, var(--space-6), wrap);
      @include custom-scroll();
    }
  }
}
// Event Card Big extend
%event-card-big {
  .card,
  .program {
    &-item {
      max-width: 30.2rem;
      height: 20rem;
      border: .1rem solid var(--neutral-20);
      background: var(--neutral-0) linear-gradient(223deg, var(--informative-10) 0%, hsl(var(--hsl-neutral-5) / 0) 75.04%);
      isolation: isolate;
      overflow: hidden;
      @extend %p-5-4;
      @extend %border-radius-m;
      @extend %flex-sb-fe;
      @extend %relative;
      @extend %gap-4;
      &:not(:last-child) {
        @extend %mb-6;
      }
      &:hover {
        border-color: var(--dark-focus-0);
      }
      &::before,
      &::after {
        content: "";
        pointer-events: none;
        @extend %zindex-pattern;
      }
      &::before {
        height: 23rem;
        aspect-ratio: 1;
        background: url(/static-assets/images/svg/vector.svg?v=#{$image-version}) center / contain no-repeat;
        filter: invert(70%) sepia(96%) saturate(700%) hue-rotate(200deg) brightness(80%) contrast(250%);
        opacity: 1;
        @include position(null, null, -9%, 35%);
      }
      &::after {
        background: linear-gradient(180deg, hsl(var(--hsl-neutral-0) / 0) 0%, hsl(var(--hsl-neutral-0) / 1) 100%);
        @include position-combo(inset);
      }
    }
    &-content .text {
      margin-bottom: var(--space-2);
      display: inline-block;
      color: var(--primary-50);
      font-size: 1.6rem;
    }
  }
  .title {
    line-height: 1.4;
    @include truncate-text(3);
    @extend %mb-0;
    @extend %primary-50;
    @extend %title-40;
  }
  .btn-link {
    @extend %flex;
    &::before {
      line-height: 1;
      @include icon(arrow-right, 24);
      @extend %primary-50;
    }
    &::after {
      content: "";
      @include position-combo(inset);
    }
    .text,
    .btn-text {
      @extend %font-0;
    }
  }
  @include mq(col-tablet) {
    .card,
    .program {
      &-list {
        @include card-count(3, var(--space-4), wrap);
      }
      &-item {
        max-width: unset;
        &:not(:last-child) {
          margin-bottom: 0
        }
      }
    }
  }
  @include mq(col-desktop) {
    .card-list,
    .program-list {
      @include card-count(4, var(--space-6), wrap);
      @include custom-scroll();
    }
  }
}
// Common Gradient
%common-gradient {
  &::after,
  &::before {
    content: "";
    pointer-events: none;
    @extend %w-100;
  }
  &::after {
    height: 59%;
    background: linear-gradient(180deg, hsl(var(--hsl-neutral-100) / 0) 0%, hsl(var(--hsl-neutral-100) / 0.99) 69.25%, hsl(var(--hsl-neutral-100) / 1) 100%);
    @extend %position-b-l;
  }
  &::before {
    height: 60%;
    background: linear-gradient(360deg, hsl(var(--hsl-neutral-100) / 0) 0%, hsl(var(--hsl-neutral-100) / 1) 87.5%);
    @extend %position-t-l;
    @extend %zindex-overlay-icon;
  }
  @include mq(col-tablet) {
    &::after {
      height: 74%;
    }
    &::before {
      height: 45%;
    }
  }
}
.btn,
button {
  &:disabled {
    opacity: 0.9;
    pointer-events: none;
    color: var(--neutral-40);
    @extend %bg-informative-10;
  }
}
//No Data
%no-data {
  .no-data-container {
    @extend %bg-neutral-5;
    @extend %border-radius-m;
    @extend %p-10;
    @extend %flex-c-c;
    .text {
      @extend %title-30;
      @extend %neutral-100;
      @extend %text-center;
    }
  }
}
//ranking-table
%ranking-table {
  .table {
    &.men {
      .card-thumbnail {
        border-color: var(--primary-50);
      }
    }
    &.women {
      .card-thumbnail {
        border-color: var(--secondary-70);
      }
    }
    &-wrapper {
      @extend %p-y-12-20;
    }
    &-head {
      border-radius: 1.6rem 1.6rem 0 0;
      @include border(1, neutral-20, 10, x);
      @include border(1, neutral-20, 10, top);
      .card-banner {
        aspect-ratio: 16/9;
      }
      @extend %hidden;
      .image {
        object-fit: cover;
        filter: brightness(0.8);
        @extend %w-100;
        @extend %h-100;
      }
    }
    &-row-wrapper {
      @extend %flex;
      @extend %w-100;
    }
    &-row {
      @include border(1, neutral-20, 10, x);
      @extend %p-4;
      @extend %gap-1;
      .card {
        &-name {
          @extend %flex-fs-c;
          @extend %gap-1;
          @extend %mb-0;
        }
        &-thumbnail {
          width: 4rem;
          height: 4rem;
          aspect-ratio: 1;
          border-width: 0.2rem;
          border-style: solid;
          flex-shrink: 0;
          @extend %circle-radius;
          img {
            aspect-ratio: 1;
            @extend %circle-radius;
          }
        }
        &-logo {
          flex-shrink: 0;
          width: 1.6rem;
          height: 1.6rem;
          @extend %flex;
          img {
            object-fit: cover;
            @extend %circle-radius;
            @extend %w-100;
            @extend %h-100;
          }
        }
      }
      &:not(:first-child) {
        position: relative;
        @extend %gap-2;
        .card {
          &-logo {
            width: 1.6rem;
            height: 1.6rem;
          }
        }
        .time {
          @extend %pr-2;
        }
      }
      &:last-child {
        border-radius: 0 0 1.6rem 1.6rem;
        @extend %hidden;
      }
      &:first-child .card-name {
        max-width: 100%;
      }
      &.neutral {
        .ranking {
          .text:after {
            content: '-';
            @extend %primary-50;
          }
        }
      }
      &.ascent {
        .ranking {
          .text {
            @extend %positive-90;
          }
          .text:after {
            @include icon(arrow-up, 8);
            @extend %positive-50;
          }
        }
      }
      &.decrease {
        .ranking {
          .text {
            @extend %negative-60;
          }
          .text:after {
            @include icon(arrow-down, 8);
            @extend %negative-50;
          }
        }
      }
    }
    &-data {
      height: auto;
      flex-shrink: 0;
      justify-content: flex-start;
      &.ranking {
        width: 3.3rem;
        .text {
          @extend %flex-sb-c;
          &:after {
            content: '';
            line-height: 1;
            width: 2rem;
            height: 2rem;
            @include flex-config(inline-flex, null, center, center);
            @extend %ml-1;
          }
        }
      }
      &.info {
        width: calc(100% - 13rem);
        gap: var(--space-2);
      }
      &.time {
        width: 8.1rem;
        justify-content: flex-end;
        .text {
          @extend %text-mb;
          @extend %neutral-70;
        }
      }
    }
    &-footer {
      @extend %mt-4;
      @extend %flex-c-c;
      & > a {
        @extend %btn-default-dark;
        @extend %gap-2;
        &:after {
          @include icon(arrow-right, 19);
        }
      }
    }
    .text {
      @extend %neutral-100;
    }
    .card-name {
      max-width: calc(100% - 7.2rem);
      @extend %text-left;
      .player-redirect {
        word-break: break-word;
        max-width: max-content;
        @extend %w-100;
        @extend %text-lb;
        @extend %primary-50;
        &:hover {
          @extend %primary-55;
        }
      }
      .text {
        line-height: 1.5;
        @extend %text-lb;
        @extend %primary-50;
        &:hover {
          @extend %primary-55;
        }
      }
    }
  }
}
//player-list
%player-list {
  .player {
    &-item {
      border-bottom: .1rem solid var(--neutral-20);
      @extend %p-4;
      @extend %flex;
      @extend %gap-6;
      &:hover {
        @extend %bg-neutral-5;
      }
    }
    &-body {
      @extend %flex;
      @extend %gap-4;
    }
    &-thumbnail {
      width: 4rem;
      height: 4rem;
      aspect-ratio: 1/1;
      border: .2rem solid;
      flex-shrink: 0;
      @extend %circle-radius;
      @extend %flex;
    }
    &-img {
      flex-shrink: 1;
      object-fit: cover;
      object-position: center;
      @extend %circle-radius;
      @extend %w-100;
      @extend %h-100;
    }
    &-name {
      line-height: 1;
      // @include truncate-text(1);
      @extend %mb-2;
    }
    &-meta {
      @extend %flex-n-c;
    }
    &-bio {
      margin-left: -5.6rem;
      @extend %mt-4;
      .text {
        color: var(--neutral-70);
        font-size: 1.4rem;
        line-height: 1.5;
        @include truncate-text(2);
      }
    }
  }
  .name {
    @extend %title-50;
  }
  .meta {
    &-item {
      @extend %neutral-70;
      @extend %text-l;
      &:not(:last-child)::after {
        content: "";
        width: .4rem;
        aspect-ratio: 1;
        @extend %circle-radius;
        @extend %mr-2;
        @extend %bg-neutral-20;
      }
    }
    &-origin {
      @extend %flex-n-c;
      @extend %gap-2;
      img {
        width: 1.6rem;
        min-width: 1.6rem;
        aspect-ratio: 1;
        object-fit: cover;
        @extend %circle-radius;
      }
    }
    &-name {
      @extend %flex;
    }
  }
  .men {
    .player {
      &-thumbnail {
        border-color: var(--primary-50);
      }
    }
  }
  .women {
    .player {
      &-thumbnail {
        border-color: var(--secondary-70);
      }
    }
  }
  @include mq(col-tablet) {
    .player {
      &-item {
        padding-block: var(--space-6);
      }
      &-body {
        gap: var(--space-6);
        width: 100%;
      }
      &-thumbnail {
        width: 6.4rem;
        height: 6.4rem;
        border-width: .4rem;
      }
      &-info {
        width: 100%;
        flex-wrap: wrap;
        gap: var(--space-4);
        @include flex-config(flex, null, space-between);
      }
      &-name {
        // height: 3.6rem;
        margin-bottom: 0;
        flex-grow: 1;
        max-width: calc(100% - 17rem);
      }
      &-meta {
        height: 3.6rem;
        width: max-content;
        flex-shrink: 0;
      }
      &-bio {
        margin-top: 0;
        margin-left: 0;
        flex-basis: 100%;
      }
    }
    .meta {
      &-item {
        font-size: 1.6rem;
      }
    }
    .name {
      font-size: 2.4rem;
      letter-spacing: -.048rem;
    }
  }
}
//calendar event item
%event-item {
  .card {
    &-item {
      @include border(1, neutral-20, 10, y);
      display: grid;
      grid-template-columns: auto 18rem;
      @extend %p-4;
      @extend %relative;
    }
    &-left {
      @extend %flex-c-c;
    }
    &-right {
      @include border(1, neutral-20, 10, left);
      @extend %pl-4;
      @extend %ml-4;
    }
    &-date {
      @extend %d-block;
      @extend %text-center;
      @extend %text-lb;
      em {
        @extend %d-block;
        @extend %mt-1;
        @extend %text-s;
        @extend %neutral-70;
      }
    }
    &-meta {
      @extend %flex-column-n-n;
      @extend %gap-2;
    }
    &-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      @extend %w-100;
      @extend %mt-2;
      @extend %text-lb;
      @extend %mb-4;
      @extend %hidden;
    }
  }
  .meta {
    @extend %text-s;
    @extend %neutral-70;
    @extend %d-block;
    @extend %gap-1;
    @extend %flex-n-c;
    &::before {
      @extend %neutral-70;
    }
    &-origin {
      &::before {
        @include icon(map-pin, 16);
      }
    }
    &-category {
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      @extend %hidden;
      &::before {
        vertical-align: text-top;
        @extend %mr-1;
        @include icon(trophy, 16);
      }
    }
  }
}
// Standings Table Row 1
%standings-first {
  .table {
    &-row {
      &:first-child {
        @extend %relative;
        @extend %px-6;
        @extend %pt-14;
        @extend %pb-6;
        .card-thumbnail {
          width: 9.6rem;
          height: 9.6rem;
          top: -4.8rem;
          border-width: 0.4rem;
          @extend %bg-neutral-0;
          @extend %circle-radius;
          @extend %position-x-center;
          .thumb {
            @extend %w-100;
            @extend %h-100;
          }
        }
        .card-logo {
          width: 2.4rem;
          height: 2.4rem;
          position: absolute;
          left: calc(50% + 4rem);
          top: 2rem;
          transform: translateX(-50%);
        }
        .table-data {
          .text {
            @extend %title-50;
          }
          &.time > .text {
            @extend %neutral-70;
          }
        }
      }
    }
  }
}
//New Standings Table for all rows
%standings-all {
  .table {
    &-row {
      padding-top: var(--space-14);
      padding-bottom: var(--space-6);
      @extend %relative;
      @extend %px-6;
      .card-thumbnail {
        width: 9.6rem;
        height: 9.6rem;
        top: -4.8rem;
        border-width: 0.4rem;
        @extend %bg-neutral-0;
        @extend %circle-radius;
        @extend %position-x-center;
        .thumb {
          @extend %w-100;
          @extend %h-100;
        }
      }
      .card-logo {
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        left: calc(50% + 4rem);
        top: 2rem;
        transform: translateX(-50%);
      }
      .table-data {
        .text {
          font-size: 2rem;
          font-weight: 700;
        }
        &.time > .text {
          @extend %neutral-70;
        }
      }
    }
  }
}
//Common Sports Table
%common-sports-table {
  .table {
    border: 0;
    th,
    td {
      border-width: 0 0 0.1rem 0;
      border-bottom: 0.1rem solid var(--neutral-20);
      @extend %text-center;
    }
    th {
      font-weight: 500;
      @extend %body-lb;
      @extend %pt-0;
      &::before {
        @extend %d-block;
      }
      &.run::before {
        @include icon(run, 40);
      }
      &.bike::before {
        @include icon(bike, 40);
      }
      &.swim::before{
        @include icon(swim,45);
      }
    }
    td {
      @extend %p-4-3;
      @extend %neutral-70;
    }
  }
}
// Article Tagline
%article-tagline {
  .article-tagline {
    flex-wrap: wrap;
    order: -1;
    @extend %flex;
    @extend %gap-4;
    .text {
      min-height: 3rem;
      background: hsl(var(--hsl-neutral-10) / 0.15);
      width: max-content;
      @extend %btn;
      @extend %neutral-0;
      @extend %uppercase;
    }
  }
}
// Development page head gradient bg
%development-page-head-bg {
  margin-inline: var(--space-2-neg);
  .layout-wrapper {
    max-width: unset;
  }
  .waf-head {
    height: 43rem;
    border-radius: 0 0 var(--border-radius-m) var(--border-radius-m);
    contain: paint;
    @include background(var(--neutral-100), "cssimages/pattern/development-bg-m.png", top center / cover no-repeat);
  }
  .head-wrap {
    // padding-block: 18.2rem 17rem;
    @include default-wrapper();
    @extend %h-100;
    &:has(.head-wrap-logo) {
      flex-direction: column-reverse;
      @extend %flex-c-n;
    }
    .head-wrap-logo {
      height: 6.1rem;
      width: auto;
      margin-left: auto;
      transform: translateY(-3rem);
    }
  }
  .title {
    @extend %mb-0;
    @extend %neutral-0;
    @extend %title-30;
  }
  .waf-body {
    margin-top: -8rem;
    @extend %relative;
  }
  @include mq(col-tablet) {
    .waf-head {
      height: 45rem;
      border-radius: 0 0 var(--border-radius-xl) var(--border-radius-xl);
      @include background(var(--neutral-100), "cssimages/pattern/development-bg-d.png", bottom center / cover no-repeat);
      &::before {
        width: 58.9%;
        top: -34.3%;
        left: 45%;
      }
    }
    .head-wrap {
      padding-block: 23.3rem 15.2rem;
      &:has(.head-wrap-logo) {
        flex-direction: row;
        align-items: center;
        .head-wrap-logo {
          transform: translateY(0rem);
        }
      }
    }
    .title {
      font-size: 5.6rem;
      line-height: 1.3;
    }
    .waf-body {
      margin-top: -9rem;
    }
  }
}
// Member card list styles (In page Development - Team)
%member-item {
  .card,
  .player {
    &-item {
      border: .1rem solid var(--neutral-20);
      flex-shrink: 0;
      @extend %border-radius-s;
    }
    &-img {
      border-radius: var(--border-radius-s) var(--border-radius-s) 0 0;
      object-fit: cover;
      object-position: top center;
      @extend %w-100;
      @extend %h-100;
    }
    &-content,
    &-info {
      @extend %p-4;
    }
    &-name {
      @include truncate-text(1);
    }
  }
  .name {
    font-weight: 700;
    @extend %title-60;
  }
  .meta {
    &-origin {
      @extend %flex-n-c;
      @extend %gap-2;
      img {
        aspect-ratio: 1;
        width: 1.6rem;
        @extend %circle-radius;
      }
      .team-name {
        @extend %neutral-70;
        @extend %uppercase;
      }
    }
  }
}
//document link
%document-link {
  .document {
    &-list {
      list-style-type: disc;
      @extend %pl-4;
    }
    &-item {
      @extend %primary-50;
    }
    &-link {
      @extend %body-l;
      @extend %primary-50;
    }
  }
}
//common tabs p7
%vertical-tabs {
  .tab {
    &s {
      @extend %p-6;
      @extend %border-radius-m;
      @extend %bg-neutral-0;
    }
    &-name {
      cursor: pointer;
      border-left: .1rem solid var(--neutral-0);
      @extend %p-4;
      &.active {
        border-left: .1rem solid var(--focus-0);
        .btn-text {
          @extend %neutral-100;
        }
      }
    }
    &-text {
      .btn-text {
        @extend %neutral-70;
        @extend %body-m;
      }
    }
  }
}
//event listing bg
.load-bg-pattern {
  @each $event-name, $event-bg in $event-listing-bg-img {
    .waf-row-event.waf-row-#{$event-name}-events {
      @include bgImg('cssimages/#{$event-bg}-event-listing-bg.jpg', center, cover);
    }
  }
}