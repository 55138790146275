@use "../config/" as *;
$nav-icons: (
  world-ranking: "\e841",
  para-ranking: "\e841",
  continent-ranking: "\e86a",
  archive-ranking: "\e81e"
);
.livestrip {
  .header-wrapper .header-nav {
    height: calc(var(--window-inner-height) - var(--header-height) - var(--livestrip-height) - 2rem);
    @include position(100%, -100%, null, null);
  }
}
.menu-wrap {
  @include position-combo("tl");
  @extend %w-100;
}
// .body-wrap {
//   padding-top: var(--secondary-header-height);
// }
.site-header {
  z-index: var(--z-header);
  overflow-x: clip;
  @include position(0, null, null, 0, relative);
  @extend %w-100;
  .site {
    &-logo {
      flex-shrink: 0;
      height: 3.4rem;
      aspect-ratio: 21/7;
    }
    &-logo-link {
      display: flex;
      @extend %font-0;
      @extend %w-100;
      @extend %h-100;
    }
    &-login {
      width: 11.4rem;
      height: 8rem;
      @include position(null, 0, 0, null);
      @extend %m-0;
      @extend %flex-c-n;
      &::before {
        content: "";
        width: 0.1rem;
        height: 2.4rem;
        margin-block: auto;
        @include position(0, null, 0, 0);
        @extend %bg-primary-50;
      }
      .nav-anchor::before {
        display: none;
      }
      .nav-text {
        display: block;
      }
      .header-promo-logo {
        display: none;
      }
    }
  }
  .btn-search {
    width: 4.8rem;
    aspect-ratio: 1/1;
    .btn-text {
      position: relative;
      display: inline-block;
      @extend %font-0;
      &:before {
        color: var(--neutral-0); //for ios
        @include icon(search, 20);
      }
    }
  }
  .sub-ul {
    transform: scaleX(0);
    transform-origin: right;
    height: calc(100% - 27.2rem);
    padding: var(--space-0) var(--space-4);
    opacity: 0;
    .sub-nav-menu {
      height: 90%;
      overflow-y: auto;
      border-bottom: .1rem solid var(--neutral-20);
    }
  }
  .search-results-wrap {
    @extend %d-none;
  }
  .sub-nav {
    &-title {
      @extend %neutral-70;
    }
    &-link {
      height: 5.6rem;
      border-bottom: 0.1rem solid var(--neutral-20);
      @extend %p-4-2;
      @extend %flex-n-c;
      &:has(.sub-nav-anchor[aria-expanded="true"], .sub-nav-anchor:focus) {
        border-color: var(--primary-50);
      }
      .sub-nav-wrap {
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.3s ease-in-out;
      }
      &.submenu {
        @extend %d-block;
        > .sub-nav-anchor {
          &:after {
            @include icon(chevron-right, 20);
          }
        }
      }
    }
    &-wrap {
      + .sub-nav-wrap {
        @extend %mt-8;
      }
      &.active {
        transform: scaleX(1);
        overflow-y: auto;
        @extend %d-block;
        @extend %position-t-l;
        @extend %w-100;
        @extend %h-100;
        @extend %bg-neutral-0;
        @extend %px-4;
      }
    }
    &-anchor {
      @extend %flex-sb-c;
      @extend %w-100;
    }
    &-text {
      @extend %font-16-pb;
      @extend %neutral-100;
    }
    &-related {
      @extend %d-none;
      background-color: var(--neutral-5);
      flex-basis: 66.67%;
    }
    &-description {
      @extend %d-none;
    }
  }
  .top-trending-section,
  .live-event-section {
    flex-basis: 50%;
    padding: var(--space-4);
    > .title {
      @extend %mb-0;
      @include title-with-icon(null, arrow-trending-up, 24);
    }
  }
  .top-trending-section {
    .meta {
      font-weight: 700;
      @extend %flex-n-c;
      @extend %gap-2;
      @extend %neutral-70;
      &-origin {
        @extend %gap-1;
        .image {
          width: 1.6rem;
          aspect-ratio: 1/1;
          @extend %circle-radius;
        }
      }
      &-date {
        @extend %flex-n-c;
        &::before {
          content: "";
          width: 0.2rem;
          height: 0.2rem;
          @extend %gap-2;
          @extend %circle-radius;
          @extend %bg-neutral-70;
        }
      }
    }
  }
  .live-event-section {
    > .title {
      &::before {
        content: "\e837";
      }
    }
  }
  .trending-event {
    &-list {
      @extend %mt-8;
    }
    &-item {
      &:not(:last-child) {
        @extend %mb-4;
        @extend %pb-4;
        @include border(1, neutral-20, 10, bottom);
      }
      .title {
        @extend %body-lb;
        @extend %mb-3;
      }
    }
  }
  .link {
    background: linear-gradient(268.76deg, var(--primary-90) -0.19%, var(--primary-50) 99.83%);
    @extend %d-block;
    @extend %p-10;
    @extend %mt-14;
    @extend %border-radius-m;
  }
  .nav-back {
    @extend %w-100;
    @extend %text-left;
    @extend %d-none;
    @extend %p-6-4;
    @extend %d-none;
    .nav-text {
      @extend %font-0;
      &::before {
        @include icon(arrow-left, 24);
      }
    }
  }
  .nav-link-athlete,
  .nav-link-more {
    &:has(.sub-ul.active) {
      .nav-anchor {
        height: auto;
        @include position(2.4rem, null, null, 5.6rem);
        .nav-text {
          @extend %neutral-70;
        }
      }
      .nav-text {
        @extend %title-50;
      }
    }
    .sub-ul {
      > .sub-nav-title {
        @extend %d-none;
      }
    }
  }
  .nav-link-media {
    .sub-nav-related {
      @extend %p-4;
    }
    .head-wrap {
      margin-bottom: 0;
    }
    .title {
      @extend %body-mb;
      @extend %neutral-70;
      @extend %mb-10;
    }
  }
  .latest-news {
    @include article-listing(horizontal);
    .article {
      &-content {
        width: 67%;
        padding-left: var(--space-2);
      }
      &-thumbnail {
        width: 33%;
      }
      &-title {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
  }
  .latest-videos,
  .latest-photos {
    @extend %w-100;
    @include article-listing(overlay);
    .article {
      &-list {
        grid-template-columns: repeat(2, 1fr);
        gap: var(--space-6);
      }
      &-meta {
        font-size: 1.4rem;
      }
      &-title {
        font-size: 2rem;
        line-height: 2.8rem;
      }
    }
  }
  @include hamburger();
}
.social-menu-wrapper {
  .social {
    &-link-list {
      width: calc(100% - 11.4rem);
      @extend %px-8;
    }
  }
}
.header {
  &-wrapper {
    height: var(--header-height);
    @extend %flex-n-c;
    @extend %flex-sb-c;
    @extend %px-3;
    &:has(.nav-anchor[aria-expanded="true"]) {
      .nav-link:active {
        border-color: var(--primary-50);
      }
      .nav-anchor[aria-expanded="true"] {
        + .sub-ul {
          transform: scaleX(1);
          opacity: 1;
        }
      }
    }
    &:has(.nav-link-list .nav-anchor[aria-expanded="true"]) {
      .nav-back {
        display: block;
      }
    }
    &:has(.nav-back[aria-expanded="true"]) {
      .sub-ul {
        transform: scaleX(0);
        opacity: 0;
      }
      .nav-back {
        display: none;
      }
    }
    .header {
      &-nav {
        height: calc(var(--window-inner-height) - var(--header-height) - 2rem);
        box-shadow: 0 0.1rem 0.2rem var(--neutral-100);
        z-index: var(--z-header-nav);
        width: calc(100% - 1.6rem);
        transition: right 0.3s linear;
        @include position(var(--header-height), -100%, null, null, fixed);
        @extend %m-auto;
        @extend %bg-neutral-0;
        @extend %border-radius-m;
        @extend %flex-column-sb-n;
        .header-promo-item .nav-anchor {
          &::before {
            position: unset;
          }
        }
        .nav {
          &-link-list {
            height: calc(100% - 29.9rem);
            @extend %y-scroll;
            @extend %p-2-4;
          }
          &-link {
            height: 5.6rem;
            border-bottom: 0.1rem solid var(--neutral-20);
            @extend %p-4-2;
            @extend %flex-n-c;
            &.submenu {
              > .nav-anchor {
                &:after {
                  @include icon(chevron-right, 20);
                }
              }
            }
          }
          &-text {
            @extend %font-16-pb;
            @extend %neutral-100;
          }
          &-anchor {
            @extend %h-100;
            @extend %w-100;
            @extend %flex-sb-c;
            &::before {
              content: '';
              height: 2.2rem;
              @include position(null, null, -2.2rem, 0);
              @extend %bg-transparent;
              @extend %w-100;
              // @extend %d-none;
            }
          }
        }
      }
      &-redirect {
        flex-wrap: wrap;
        @extend %flex;
        &:not(:has(.site-login)) {
          .social-menu-wrapper {
            flex-basis: 100%;
          }
        }
      }
    }
  }
  &-promo-link {
    // height: 6.9rem;
    order: -1;
    border-bottom: 0.1rem solid var(--neutral-20);
    @extend %flex-c-c;
    @extend %w-100;
    @extend %py-4;
    .nav {
      &-text {
        @extend %d-none;
      }
      &-anchor {
        @extend %h-100;
        // &::before {
        //   content: '';
        //   @extend %w-100;
        //   @extend %h-100;
        // }
        &::after {
          @extend %d-none;
        }
      }
    }
  }
  &-promo-item {
    width: 15rem;
    height: 2.1rem;
    @extend %px-6;
    @extend %my-2;
    &:last-child {
      @extend %px-0;
      @extend %ml-6;
    }
    &.header-promo-text {
      width: auto;
      .header-promo-logo,
      .nav-anchor::before {
        @extend %d-none;
      }
      .nav-anchor {
        align-items: center;
      }
      .nav-text {
        font-size: 2rem;
        @extend %text-mb;
        @extend %neutral-0;
        @extend %flex;
      }
    }
    &.tri-logo-mob {
      margin-left: 0rem;
      display: block;
      // width: 15.6rem;
      padding-inline: 0;
      .nav-anchor {
        &::before {
          display: none;
        }
      }
    }
    &.tri-logo-web {
      display: none;
    }
  }
  &-promo {
    @extend %flex-column-n-c;
    @extend %gap-3;
  }
}
.social-menu-wrapper {
  // flex-basis: 76%;
  flex-basis: 100%;
  overflow-x: auto;
  .social {
    &-link-list {
      padding-block: 0;
      height: 8rem;
      @extend %flex-c-c;
    }
    &-head {
      @extend %d-none;
    }
    &-list {
      overflow-x: auto;
      max-width: 100%;
      @extend %py-1;
      @extend %gap-6;
      @extend %flex-n-c;
      @extend %relative;
    }
    &-text {
      @extend %font-0;
      &::before {
        font-size: 1.4rem;
        @extend %neutral-100;
      }
    }
  }
}
.login-section {
  display: flex;
  align-items: center;
  height: 100%;
  .btn-text {
    @extend %neutral-100;
    @extend %text-mb;
  }
}
.sub-ul {
  overflow-y: auto;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
  @include custom-scroll();
  @include position(7.2rem, null, null, 0);
  @extend %bg-neutral-0;
  @extend %p-4-6;
  @extend %border-radius-m;
  @extend %flex-column;
  @extend %gap-4;
  @extend %w-100;
  .nav-text {
    @extend %neutral-100;
  }
}
.wtc-page,
.wtc-races-page,
.wtc-event-page {
  .secondary-nav {
    .secondary-nav {
      &-wrapper {
        transform: scaleY(1);
        // overflow: unset;
      }
      &-list {
        transform: unset;
      }
      &-link {
        .sub-ul {
          position: fixed;
          top: calc(100% - 4.5rem);
        }
      }
    }
    .secondary-nav-list {
      @extend %px-4;
    }
  }
  &.hamburger-active {
    .secondary-nav {
      @extend %zindex-headernegative;
    }
    .site-header {
      @extend %zindex-header;
    }
    .btn-hamburger {
      &::after {
        color: var(--neutral-0);
      }
      &[aria-expanded=true] {
        &::after {
          color: var(--primary-50);
        }
      }
    }
  }
}
.wtc-page {
  // .body-wrap {
  //   padding-top: calc(var(--header-height) + var(--header-redirect-height));
  // }
  .btn-hamburger {
    @extend %zindex-header;
    &::after {
      color: var(--neutral-100);
    }
  }
  &.sticky {
    .btn-hamburger {
      position: fixed;
      left: unset;
    }
    &.scroll-up {
      .btn-hamburger {
        position: unset;
        &::after {
          color: var(--neutral-100);
        }
      }
    }
  }
  .header-nav {
    top: var(--secondary-header-height)
  }
  .site-header {
    z-index: unset;
  }
  .secondary-nav {
    .secondary-nav {
      &-link {
        .sub-ul {
          top: calc(100% + 1rem);
        }
      }
      &-link {
        &:hover {
          .sub-ul {
            transition-delay: 0ms;
            transform: scaleY(1);
          }
        }
        .sub-ul {
          position: absolute;
          overflow-y: auto;
          // padding-top: 4rem;
          transition-delay: 200ms;
          // scrollbar-width: none;
          // background-color: unset;
          // box-shadow: unset;
          // top: 100%;
          // top: calc(var(--secondary-header-height) - var(--space-2));
          // &::before {
          //   content: '';
          //   width: 100%;
          //   height: calc(100% - 2.5rem);
          //   background: var(--neutral-0);
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   z-index: -1;
          //   box-shadow: 0 .4rem 2.5rem 0 rgba(0, 0, 0, .2);
          //   border-radius: 1.6rem;
          // }
          // &.active {
          //   transform: unset;
          // }
          @include custom-scroll();
        }
      }
    }
  }
}
.wtc-page,
.wtc-races-page {
  &.sticky {
    &.hamburger-active {
      .btn-hamburger {
        position: unset;
      }
      .site-header {
        position: fixed;
      }
    }
    .btn-hamburger {
      &::after {
        @extend %neutral-100;
      }
    }
  }
}
.secondary-nav {
  @extend %zindex-secondary-nav;
  @extend %position-t-l;
  @extend %w-100;
  @extend %bg-neutral-0;
  .logo {
    min-width: var(--secondary-logo-width);
    width: var(--secondary-logo-width);
    aspect-ratio: 21/9;
    @extend %m-auto;
    @extend %text-center;
    @extend %flex-n-c;
    // @extend %py-3;
    &-text {
      @extend %font-0;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  .secondary {
    &-nav-list {
      overflow-x: auto;
      overflow-y: clip;
      // overflow-x: visible;
      transform: scale(1);
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @extend %flex-n-n;
      // @extend %y-hidden;
      @extend %px-3;
      @extend %gap-2;
      .secondary-nav-link {
        &:has(.secondary-nav-anchor[aria-expanded="true"]) {
          .secondary-nav-list {
            overflow-x: clip;
            overflow-y: visible;
          }
          .sub-ul {
            transform: scaleY(1);
          }
        }
        &:has(.secondary-nav-anchor[aria-expanded="false"]) {
          .sub-ul {
            transform: scaleY(0);
          }
        }
      }
    }
    &-nav-link {
      height: 4rem;
      @extend %flex-n-c;
      @extend %px-2;
      // @extend %relative;
      &.active,
      &:hover {
        border-radius: var(--border-radius-xl);
        @include bg(neutral-0, 3);
      }
      &.active {
        border: .2rem solid var(--neutral-20);
      }
      .sub-ul {
        overflow-y: unset;
        top: calc(var(--secondary-header-height) + var(--space-2));
        width: calc(100% - var(--space-4));
        transition: transform 0.3s ease-in-out;
        box-shadow: 0 0.4rem 2.5rem 0 hsla(0, 0%, 0%, 0.2);
        @include position(null, 0, null, 0);
        @extend %mx-auto;
        .nav-anchor {
          width: 100%;
          display: block;
        }
      }
      &.back-btn {
        position: fixed;
      }
    }
    &-nav-anchor {
      @extend %h-100;
      @extend %flex-n-c;
      @extend %gap-2;
      &[aria-expanded] {
        &:after {
          @include icon(chevron-down, 20);
        }
      }
      &::before {
        content: '';
        height: 3rem;
        // visibility: hidden;
        @include position(100%, null, null, 0);
        @extend %bg-transparent;
        @extend %w-100;
      }
    }
    &-nav-wrapper {
      height: var(--secondary-header-height);
      @extend %relative;
    }
  }
  .nav {
    &-text {
      width: max-content;
      @extend %text-mb;
      @extend %neutral-100;
    }
  }
}
.sticky {
  .secondary-nav {
    .secondary-nav-link {
      &.active {
        @include bg(neutral-5, 1);
      }
    }
  }
}
.sticky,
.wtc-page {
  .header-nav {
    height: calc(var(--window-inner-height) - var(--header-height) - 6rem);
    // top: calc(var(--secondary-header-height) + 1rem);
    top: 7rem;
  }
}
.wtc-races-header {
  .secondary-nav {
    .sub-ul {
      top: calc(100% + 2rem);
    }
  }
}
@supports (-webkit-touch-callout: none) {
  .site-header {
    .sub-ul {
      .sub-nav-menu {
        height: 87%;
        overflow-y: auto;
      }
    }
  }
}
//media query
@media (max-width:$md-min-width) {
  .site-header {
    .header-redirect {
      display: none;
    }
    .header-wrapper {
      .header-redirect {
        display: flex;
      }
    }
  }
  .secondary-nav {
    .secondary-nav-link {
      height: 3rem;
    }
  }
}
@include mq(col-tablet) {
  .site-header {
    overflow-x: unset;
    display: flex;
    flex-direction: column;
    .header {
      &-redirect {
        height: var(--header-redirect-height);
        width: 100%;
        z-index: var(--z-header);
        // gap: 2.4rem;
        padding: 1.2rem 3.2rem;
        @include flex-config(flex, row, flex-end);
        & > div,
        section {
          &:not(:last-child) {
            padding-right: var(--space-6);
            @include separator(100%, y, 0.1rem, var(--primary-50));
          }
        }
        &.d-none {
          display: none;
        }
      }
      &-wrapper {
        padding: var(--space-4) var(--space-10);
        transform: scale(1);
        .btn-hamburger {
          display: none;
        }
        .header-nav {
          position: relative;
          right: unset;
          top: unset;
          height: 4rem;
          background: initial;
          flex-direction: row;
          width: calc(100% - 20rem);
          box-shadow: unset;
          &:has(.sub-ul.active) .nav-back {
            display: none;
          }
          .header-redirect {
            display: none;
          }
          &:has(.nav-link) {
            &:has(.nav-anchor[aria-expanded="true"]) {
              .nav-back {
                display: none;
              }
            }
          }
        }
        .nav-link {
          position: relative;
          cursor: pointer;
          border: .2rem solid transparent;
          &.active,
          &:hover {
            @include bg(neutral-0, 3);
          }
          &,
          &-list {
            padding: 0;
            height: 100%;
            border: none;
          }
          &-list {
            display: flex;
            gap: var(--space-3);
            margin-inline: auto;
            overflow-y: hidden;
            overflow-x: auto;
          }
          &.submenu {
            display: flex;
          }
        }
        .nav-anchor {
          padding-inline: var(--space-2);
          gap: var(--space-1);
          width: max-content;
          position: relative;
          &::before {
            display: block;
          }
          &::after {
            rotate: 90deg;
            font-size: 1.5rem;
            @include color(neutral-5);
          }
        }
        .nav-text {
          font-size: 1.4rem;
          @include color(neutral-5);
        }
      }
      &-promo-link {
        order: unset;
        height: 100%;
        .nav {
          &-anchor {
            display: flex;
            &:after {
              content: '';
              width: 100%;
              height: 100%;
              @include bgImg('svg/logo.svg', right, contain);
            }
          }
        }
      }
      &-promo-item {
        &:not(:nth-child(3)) {
          @include separator(100%, y, 0.1rem, var(--primary-50));
        }
        &.header-promo-text {
          width: auto;
          .nav-text {
            font-size: 1.4rem;
          }
        }
        &.tri-logo-mob {
          display: none;
        }
        &.tri-logo-web {
          display: block;
        }
      }
    }
    .sub-ul {
      box-shadow: 0 0.4rem 2.5rem 0 hsla(0, 0%, 0%, 0.2);
      transform: scaleY(0) translateX(-50%);
      transform-origin: top;
      position: fixed;
      top: calc(100% + var(--space-4));
      left: 50%;
      width: var(--container-max-width);
      height: auto;
      max-height: unset;
      display: flex;
      padding: 0;
      max-height: calc(var(--window-inner-height) - var(--header-height) - var(--header-redirect-height));
      overflow-y: auto;
      @include bg(neutral-0);
      .nav {
        &-link {
          margin-inline: 0;
          &:hover {
            all: unset;
          }
          &.active {
            border: 0;
          }
        }
        &-anchor {
          padding-inline: 0;
        }
        &-text {
          color: var(--neutral-100);
        }
      }
    }
    .site-login {
      flex-basis: unset;
      height: unset;
      &::before {
        content: unset;
      }
      .nav-text {
        color: hsl(var(--hsl-neutral-0) / 1);
        font-weight: 700;
      }
      .nav-anchor::before {
        display: none;
      }
    }
    .sub-nav {
      &-menu {
        padding: var(--space-4);
        flex-basis: 33.33%;
      }
      &-related {
        display: flex;
      }
      &-title {
        font-size: 2rem;
      }
      &-description {
        display: block;
        font-size: 1.6rem;
        margin-top: 0.8rem;
      }
      &-link {
        &.submenu {
          > .sub-nav-anchor {
            display: none;
          }
        }
        .sub-nav-wrap {
          transform: unset;
        }
      }
    }
    .nav-link-event,
    .nav-link-media,
    .nav-link-more,
    .nav-link-athlete,
    .nav-link-sport {
      .sub-ul {
        flex-direction: row;
      }
    }
    .nav-link-ranking,
    .nav-link-athlete,
    .nav-link-sport,
    .nav-link-more {
      .sub-ul {
        padding: var(--space-4);
      }
    }
    .nav-link-ranking {
      .sub-nav {
        &-list {
          @include grid(2, var(--space-6));
        }
        &-text {
          font-size: 2rem;
          position: relative;
          &::before {
            width: 4rem;
            height: 4rem;
            font-weight: 400;
            line-height: normal;
            margin-bottom: var(--space-2);
            border-radius: 0.8rem;
            color: var(--neutral-40);
            @include flex-config(flex, row, center, center);
            @include icon(trophy, 24);
            @include bg(neutral-0, 2);
          }
        }
        &-link {
          border-bottom: 0;
          padding: 0;
          height: auto;
          @each $nav-icon, $nav-code in $nav-icons {
            &.#{$nav-icon} {
              .sub-nav-text::before {
                content: $nav-code;
              }
            }
          }
        }
        &-anchor {
          border-radius: 1.6rem;
          padding: var(--space-6);
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          @include border(1, neutral-20, 10);
          &:hover {
            background-color: var(--informative-10);
            border-color: var(--focus);
            box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.1);
          }
        }
        &-title {
          margin-bottom: var(--space-6);
        }
      }
    }
    .nav-link-athlete,
    .nav-link-more,
    .nav-link-sport {
      &:has(.sub-ul.active) {
        .nav-anchor {
          height: 100%;
          position: static;
          .nav-text {
            @include color(neutral-5);
          }
        }
        .nav-text {
          font-size: 1.4rem;
          line-height: unset;
        }
      }
      .sub-ul {
        flex-wrap: wrap;
        > .sub-nav-title {
          display: block;
          width: 100%;
        }
      }
      .nav-anchor {
        .nav-text {
          @include color(neutral-5);
        }
      }
      .sub-nav-wrap {
        width: calc(50% - 8rem);
        flex-grow: 1;
        + .sub-nav-wrap {
          margin-top: 0;
        }
        &:nth-of-type(even) {
          margin-left: var(--space-20);
        }
      }
    }
    .nav-link-media {
      .sub-nav {
        &-anchor {
          &:after {
            @include icon(chevron-right, 20);
          }
        }
      }
    }
    .sub-nav-related {
      .latest-news,
      .latest-videos,
      .latest-photos {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }
  .header {
    &-wrapper {
      .header {
        &-nav {
          .nav {
            &-link-list {
              &:has(.sub-ul.active) {
                height: auto;
                overflow-y: visible;
                overflow-x: clip;
              }
            }
            &-link {
              &:hover {
                .sub-ul {
                  transform: scaleY(1) translateX(-50%);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      &:has(.nav-anchor[aria-expanded=true]) .nav-anchor[aria-expanded=true]+.sub-ul {
        transform: scaleX(1) translateX(-50%);
      }
    }
    &-promo-link {
      width: auto;
      border-bottom: 0;
      padding-block: var(--space-0);
    }
    &-promo {
      flex-direction: row;
      gap: var(--space-0);
    }
    &-nav {
      &:has(.nav-link-list.active) {
        .nav-back {
          display: none;
        }
      }
    }
  }
  .wtc-races-header,
  .wtc-page {
    .secondary-nav {
      .sub-ul {
        top: calc(100% + 3rem);
      }
    }
  }
  .wtc-page {
    .site-header {
      z-index: var(--z-header);
    }
  }
  .wtc-races-page .secondary-nav .secondary-nav-list {
    padding-inline: var(--container-white-space);
  }
  .login-section {
    .btn-text {
      @include color(neutral-0);
    }
  }
  .social-menu-wrapper {
    flex-grow: 1;
    flex-basis: unset;
    border-top: none;
    overflow-x: unset;
    .social {
      &-link-list {
        padding: 0;
        justify-content: flex-end;
        height: unset;
        width: 100%;
      }
      &-list {
        padding-block: 0;
      }
      &-text {
        &::before {
          font-size: 2.4rem;
          color: var(--neutral-0);
        }
        &.icon-youtube {
          &::before {
            font-size: 1.8rem;
          }
        }
        &.icon-twitter::before {
          font-size: 2rem;
        }
      }
    }
  }
  .user-photo,
  .list-wrap {
    display: none;
  }
  .site-header,
  .secondary-nav {
    .nav-link,
    .secondary-nav-link {
      border: 0.2rem solid transparent;
      &.active,
      &:hover {
        border-radius: var(--border-radius-xl);
      }
      &.active {
        border: 0.2rem solid var(--neutral-20);
      }
    }
  }
  //secodary header
  .secondary-nav {
    top: calc(var(--header-height) + var(--header-redirect-height));
    .secondary-nav {
      &-wrapper {
        @include flex-config(flex, null, center, center);
        .secondary-nav-list .secondary-nav-link:hover .sub-ul {
          transform: scaleY(1);
        } 
      }
      &-link {
        cursor: pointer;
        height: 4.8rem;
        &.secondary-nav-link-coaches .sub-ul {
          width: 41rem;
        }
      }
      &-anchor {
        padding-inline: var(--space-2);
      }
    }
    .logo {
      margin-inline: var(--space-3);
      width: auto;
      height: 4.8rem;
    }
    .sub-ul {
      .nav-link.active {
        border: 0;
      }
    }
  }
  .wtc-event-page,
  .wtc-races-page {
    .secondary-nav {
      .secondary-nav {
        &-link {
          .sub-ul {
            top: calc(100% + 1rem);
          }
        }
      }
    }
  }
}
@include mq(col-lg) {
  .scroll-up {
    .site-header .sub-ul {
      top: var(--header-height);
    }
  }
  .site-header {
    .nav-link-ranking,
    .nav-link-athlete,
    .nav-link-sport,
    .nav-link-more {
      .sub-ul {
        padding: var(--space-14) var(--space-10);
      }
    }
    .latest-videos,
    .latest-photos {
      .article {
        &-list {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
    .sub-ul {
      width: var(--lg-container-max-width);
      // left: -15rem;
    }
  }
}
@include mq(col-desktop) {
  .site-header {
    .site-logo {
      height: 5rem;
    }
    .header-wrapper {
      .nav-link-list {
        overflow-x: visible;
        overflow-y: unset;
      }
    }
    .sub-nav {
      &-menu {
        padding: var(--space-14) var(--space-10);
      }
    }
    .top-trending-section,
    .live-event-section {
      padding: var(--space-14) var(--space-10);
    }
    .link {
      padding: var(--space-20);
    }
    .nav-link-ranking {
      .sub-nav {
        &-list {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
    .nav-link-athlete,
    .nav-link-more,
    .nav-link-sport {
      .sub-nav-wrap {
        width: calc(33.33% - 8rem);
        + .sub-nav-wrap {
          margin-left: var(--space-20);
        }
      }
    }
    .nav-link-media {
      .sub-nav-related {
        padding: var(--space-14) var(--space-10);
      }
    }
    .latest-news {
      .article {
        &-list {
          grid-template-columns: repeat(2, 1fr);
          gap: var(--space-8);
        }
      }
    }
  }
  .secondary-nav {
    .secondary-nav {
      &-list {
        height: unset;
        overflow-x: unset;
        overflow-y: unset;
        &:has(.sub-ul.active) {
          overflow-x: unset;
          overflow-y: unset;
        }
      }
      &-link {
        position: relative;
        padding: 0;
        .sub-ul {
          min-width: 23.1rem;
          width: unset;
          top: calc(var(--secondary-header-height) - var(--space-2));
        }
        &:last-child {
          .sub-ul {
            left: unset;
          }
        }
      }
    }
  }
  .wtc-page,
  .wtc-races-page,
  .wtc-event-page {
    .secondary-nav .secondary-nav {
      &-link {
        &:hover {
          .sub-ul {
            transition-delay: 0ms;
            transform: scaleY(1);
          }
        }
        .sub-ul {
          position: absolute;
          overflow-y: auto;
          // padding-top: 4rem;
          transition-delay: 200ms;
          // scrollbar-width: none;
          // background-color: unset;
          // box-shadow: unset;
          // top: 100%;
          top: calc(var(--secondary-header-height) - var(--space-2));
          // &::before {
          //   content: '';
          //   width: 100%;
          //   height: calc(100% - 2.5rem);
          //   background: var(--neutral-0);
          //   position: absolute;
          //   bottom: 0;
          //   left: 0;
          //   z-index: -1;
          //   box-shadow: 0 .4rem 2.5rem 0 rgba(0, 0, 0, .2);
          //   border-radius: 1.6rem;
          // }
          // &.active {
          //   transform: unset;
          // }
        }
      }
      &-list {
        &:has(.sub-ul.active) {
          overflow-x: clip;
          overflow-y: visible;
        }
      }
    }
  }
}
@include mq(col-xxl) {
  .site-header {
    .sub-ul {
      width: calc(var(--window-inner-width) - var(--container-white-space));
    }
  }
}